export const locale = {
  lang: 'fr',
  data: {
    code: "FRENCH",
    languages: {
      en: "Anglais",
      ja: "Japonaise",
      fr: "Française",
      kor: "Corée",
      china: "Chinois simplifié",
      china_tw: "Chinois traditionnel"
    },
    language: "French",
    slogan: "Découvrez les articles de vos modèles de cosplay préférés et soutenons (KAWAII)",
    menu: {
      about: "à propos de",
      feed: "nourrir",
      event: "événement",
      ranking: "classement",
      ranking_past: "classement (passé)",
      news: "nouvelles",
      movie: "film",
      search_cosplayer: "Recherche cosplayeur",
      search_post: "Recherche d'articles",
      my_page: "Ma page",
      favorite: " Préféré ",
      kawaii_history: "histoire de kawaii",
      kawaii_total: "Kawaii total",
      cosplayer_apply: "Cosplayeur s'applique",
      info_edit: "Modifier les informations",
      block_list: "Liste de blocage",
      login_setting: "Liste de blocage",
      withdrawal: "Retrait"
    },
    footer: {
      term: "Conditions d'utilisation ",
      contact_us: "Demandes de renseignements",
      faq: "FAQ",
      company: "Entreprise ",
      privacy: " Traitement des informations personnelles ",
      commercial_code: " Affichage basé sur le droit des transactions de droit commercial spécifié",
      company_info: "Affichage basé sur le droit de règlement des fonds",
      shiketsuhou: "Guide de la société exploitante"
    },
    login_twitter: "Connectez-vous avec Twitter",
    email: "Email",
    password: "Mot de passe",
    login: "S'identifier",
    email_error: "Veuillez saisir la bonne adresse e-mail",
    password_min: "Le mot de passe doit contenir au moins 4 caractères",
    forgot_password: "Mot de passe oublié",
    register: "enregistrement",
    email_required: "Adresse e-mail est nécessaire",
    password_required: "Mot de passe requis",
    login_error: "The email address or password is different",
    reset_password: "Mot de passe oublié",
    reissue: "Rééditer",
    please_login: "Veuillez vous connecter",
    register_title: `Veuillez saisir le contenu suivant et appuyez sur "s'inscrire".`,
    nickname: "Surnom",
    nickname_min: "Veuillez entrer le surnom en 25 caractères ou moins",
    nickname_required: "Un pseudo est requis",
    confirm_password: "Confirmez le mot de passe",
    password_not_match: "Le mot de passe ne correspond pas",
    agree_term: " Acceptez les conditions d'utilisation ",
    please_agree_term: "Veuillez accepter les conditions d'utilisation",
    premiun_layer: "couche premium",
    normal_layer: "couche nominale",
    next: "Prochain",
    event: "événement",
    period_of_accept: "Période de réception",
    fan_ranking: "Classement des fans",
    post_login: 'Connectez-vous à "KAWAII"',
    event_rankings: "Classement des événements",
    today: "Aujourd'hui",
    week: "La semaine",
    month: "Mois",
    rankings: "Classement",
    normal_layer_feed: "Normal layer feed",
    points: "Points",
    more: "Plus",
    past_ranking: "Classement (passé)",
    profile: "Profil",
    posts: "Lister les articles",
    number_result: "Tendances / résultats",
    back_list: "Retour à la liste",
    change_user_info: "Modifier les informations utilisateur",
    avatar: "Avatar",
    message: "Message",
    message_max: "Veuillez saisir votre pseudo en 1 000 caractères ou moins",
    cosplayer_list: "Liste des cosplayeurs",
    search_result: "Résultat de la recherche",
    register_thank: "Merci de votre inscription.",
    click_mypage: "Cliquez ici pour ma page",
    kwaii_player: "Liste des joueurs",
    empty_kwaii_player: "Il n'y a pas de joueurs KAWAII",
    cosplayer_block: "Bloquer les cosplayers",
    cosplayer_block_empty: "Aucun joueur ne bloque.",
    change_password: "Changer le mot de passe",
    password_reconfirm: "Reconfirmer le mot de passe",
    change: "Changement",
    new_post: "Nouveau poste",
    event_join: "Rejoindre un événement",
    posting_guide: "Guide de publication",
    kawaii_achivements: "Réalisations kawaii",
    phone: "Téléphone",
    orther_url: "Autre URL",
    bank_name: "Nom de banque",
    branch_name: "Nom de la filiale",
    branch_number: "Numéro de la succursale",
    account_type: "Type de compte (normal ou découvert",
    account_number: "Numéro de compte",
    account_name: "Nom du compte",
    image: "Image",
    required: "Obligatoire",
    content: "Contenu",
    please_select: "Veuillez sélectionner",
    post_to_twitter: "Publier sur Twitter",
    post_subtitle: "Les publications de 140 caractères ou moins sont omises lors de la liaison avec Twitter",
    post: "Publier",
    post_confirm: "Puis-je l'afficher?",
    delete: "Supprimer",
    edit: "Éditer",
    confirm_delete_post: "Puis-je le supprimer?",
    post_edit: "Posté",
    join_event: {
      title: 'Événements auxquels vous pouvez assister',
      subtitle: "Ne peut être annulé après avoir participé à l'événement.",
      start: "Début d'entrée",
      participate: "Participer",
      participating: "Participante"
    },
    reception_period: "Période de réception",
    level: "Niveau",
    next_lever: "Au niveau suivant",
    my_page: {
      position: "Position",
      great_game: "C'est un excellent jeu.",
      continue_login: "connexion continue",
      great_app: "C'est une super application.",
      we_have_won: "Nous avons gagné.",
      day_two: "Jour deux"
    },
    post_detail: {
      title: "Détail du message",
      post_reflected: "KAWWAII de ce poste se reflète dans le classement lors de l'entrée",
      congratulations: "Toutes nos félicitations",
      level_is: "Le niveau est",
      we_help_you: "Nous sommes là pour vous aider.",
      stamina: "Endurance",
      from: "De",
      kwaii_not: 'KAWAII "ne peut pas!!',
      current_point: "Point actuel",
      bottom_page: "Du bas de la page",
      with: "Avec",
      kwaii_possible: '"KAWAII" est possible',
      point_purchase: "L'écran d'achat de points est ici",
      number_of_point: "Nombre de points",
      retention_points: "Points de rétention",
      input_limit: "Limite d'entrée",
      violation: "Signaler des violations",
      please_enter_points: "Veuillez saisir les points.",
      enter_points: "Entrez des points par multiples de 10.",
      points_not_enough: "Le point ne suffit pas.",
      max_lever_up: "Le nombre de points au-dessus du niveau 2 supérieur a été entré. Entrez un maximum de 1 points de niveau supérieur."
    },
    purchase: {
      add_point: "Ajouter des points",
      bonus_point: "Des points bonus seront attribués lors de votre achat.",
      enought_to_buy: "Il est devenu suffisamment rentable pour acheter ensemble",
      prossetion: "Points de possession",
      buy_with_paypal: "Achetez avec PayPal",
      view_advertisement: "View Advertisement",
      after_click: "Après avoir cliqué sur le bouton ci-dessus, sur l'écran suivant",
      email_password_required: "ID COSPO (adresse e-mail) et mot de passe",
      please_login: "Veuillez vous connecter. (Uniquement pour la première fois）",
    },
    logout_confirm: "Puis-je vraiment me déconnecter?",
    unsubscribe_confirm: "Puis-je vraiment me désinscrire?",
    withdrawal_thank: "C'était une expérience géniale.",
    notify: {
      title: 'Notification',
      cancel: "Annuler",
      close: "proche",
      confirm: "Confirmer"
    },
    about: {
      how_to_start: "Comment commencer",
      post_title: "Pour publier des photos et vous amuser dans COSPO, vous devez postuler pour Contributor",
      kawaii: "KAWAII",
      support_your_player: 'Soutenez vos couches préférées avec "KAWAII"!',
      recover_in: "KAWAII récupère en 10 minutes 1KAWAII. KAWAII peut également être récupéré en utilisant des points.",
      put_together: "Vous pouvez assembler KAWAII en utilisant les points que vous avez.",
      kawaii_to_point: "1KAWAII = 10 points",
      kwaii_lever_up: "En résumé, KAWAII ne peut entrer que le nombre avant le niveau supérieur.",
      input_limit: "L'affichage de limite d'entrée indique le nombre restant jusqu'au niveau supérieur.",
      example_input_limit: `Exemple: lorsque la limite d'entrée est de 100 pt`,
      lever_up_kwaii: "Pour passer au niveau supérieur avec KAWAII 10 fois de plus et permettre à KAWAII de récupérer complètement",
      can_only_put_together: "Vous pouvez uniquement assembler KAWAII jusqu'à 90 pt en utilisant 9KAWAII.",
      great_game: "C'est un excellent jeu.",
      first_login_change: "Lorsque la date change et la première connexion, l'écran de bonus de connexion s'affiche.",
      login_day_total: "Affiche le nombre actuel de jours de connexion consécutifs / total des jours de connexion / points gagnés.",
      lever_up: "NIVEAU SUPÉRIEUR",
      regularly_hosts: "COSPO accueille régulièrement divers événements.",
      many_types: "Il existe de nombreux types d'événements tels que la publication de magazines et les apparitions d'événements",
      determined_from_user: "Qui sera choisi sera déterminé par KAWAII à partir de l'utilisateur.",
      dream_layer_san: "Votre KAWAII pourrait être une étape qui mène au rêve de la couche San!",
      event_page: "Lors de la tenue d'un nouvel événement, nous vous informerons dans la page des nouvelles et de l'événement.",
      in_addition: "De plus, nous vous informerons à tout moment dans le magazine e-mail que COSPO distribue.",
      event_and_daily: "Le classement sera basé sur les événements et les rapports mensuels hebdomadaires quotidiens.",
      ranking_vary_according: "Le classement variera en fonction du nombre de KAWAII.",
      ranking_is_update: "Le classement est mis à jour à 0 minutes par heure, les points d'une heure sont reflétés.",
      daily: "du quotidien",
      weekly: "Hebdomadaire",
      monthly: "mensuelle",
      in_aggregate: "Dans l'ensemble",
      weekly_day: "Tous les dimanches à 0h00 du décompte du samedi 23h59",
      monthly_day: "1er jour de chaque mois 0:00 〜 fin du mois 23:59",
      kwaii_article: "KAWAII sur l'article sera immédiatement reflété.",
      fan_ranking: "Le classement des fans est le classement des utilisateurs de KAWAII dans le message.",
      in_addition_totals: "En plus des totaux mensuels hebdomadaires quotidiens, vous pouvez également voir les classements de la veille.",
      in_addition_events: "En outre, vous pouvez également voir le classement des fans pour le classement, comme les événements.",
      twitter_cooperation: "Lorsque vous utilisez la fonction de coopération Twitter, faites attention car elle ne sera pas reflétée dans le texte intégral si elle dépasse 140 caractères",
      url_post_comment: "Si l'URL est répertoriée dans le commentaire de publication, elle sera automatiquement liée.",
      post_title_1: "Après la publication, l'événement correspondant au bas de l'écran affiché s'affiche lorsque la liaison est possible.",
      post_title_2: "Vous pouvez lier un événement par article. １ un événement par article.",
      post_title_3: "Si vous participez à plusieurs événements au cours de la même période, vous devrez publier en conséquence.",
      post_title_4: "S'il n'est pas défini au moment de la publication, veuillez noter qu'il n'est pas reflété comme un point d'événement même s'il s'agit de KAWAII.",
      post_title_5: "Puisqu'il n'est pas possible de refléter le point plus tard, veuillez vérifier s'il est possible de vous en assurer.",
      post_ranking: "CLASSEMENT APRÈS",
      to_cospo: "À COSPO",
      premium_rank: "Il existe deux types de rang premium et de rang normal.",
      participate_rank: "Il y a des restrictions sur les événements qui peuvent participer en fonction du rang.",
      about_ranking: "À propos du classement jusqu'à la prime",
      rank_normal: "Vous pouvez passer du rang normal à la prime.",
      monthly_ranking: 'Classement mensuel si vous vous situez dans la position "５"',
      after_submit_application: "Après avoir soumis une demande, la demande a été examinée par l'administration    ",
      rank_up_only: "Nous ne nous classerons que si nous avons réussi la sélection et nous vous contacterons de la direction.",
      please_note: "Veuillez noter que nous ne pouvons pas répondre aux critères de sélection et aux résultats.",
      point_bonus: "BONUS DE POINT",
      kwaii_sent_viewer: "Dans COSPO, il s'agit de l'un des KAWAII envoyés par le téléspectateur à la personne qui a posté",
      kwaii_sent_points: "Selon le nombre de KAWAII (KAWAII payés) envoyés avec des points payés",
      we_will_reward: "Nous vous récompenserons.",
      kwaii_free_points: "* Les KAWAII en points gratuits ne seront pas convertis en récompenses.",
      cash_value: "Valeur de rachat",
      kwaii_to_yen: "1 KAWAII payé = 1 yen",
      point_bonus_1: "Le KAWAII acquis est agrégé à la fin de chaque mois de fonctionnement et sera transféré à la fin du mois suivant.",
      point_bonus_2: "Si le compte n'a pas été enregistré, nous vous contacterons depuis l'opération après le virement.",
      point_bonus_3: "Le paiement peut être retardé.",
      point_bonus_4: "Pour le compte de transfert, veuillez enregistrer le nom de la banque, le nom de l'agence, le numéro de compte, le nom du compte de la destination du transfert dans le champ de profil de la barre latérale.",
      point_bonus_5: "La récompense sera versée pour plus de 10 000 KAWAII payés.",
      point_bonus_6: "Si le nombre de KAWAII est inférieur à 10000 KAWAII payés, vous pourrez reporter le total après le mois suivant, et la procédure de paiement sera possible le mois où le nombre total de KAWAII payés sera atteint 10000.",
      point_bonus_7: "À l'avenir, nous prévoyons de pouvoir gérer le transfert même s'il est inférieur à 10 000 KAWAII payés uniquement si vous pouvez supporter les frais de transfert.",
      point_bonus_8: "De plus, nous prévoyons de modifier le virement afin qu'il se fasse lors de la future demande de paiement.",
      point_bonus_9: "Vous pouvez vérifier le nombre cumulé de KAWAII qui ont été acquis dans la barre latérale «Résultats d'acquisition de KAWAII",
      line: "LINE@",
      line_title_1: "C'est un excellent moyen de tirer le meilleur parti de votre travail et d'en tirer le meilleur parti.",
      line_title_2: "Nous avons une ligne dédiée @ contributeur disponible.",
      line_title_3: "Nous acceptons les demandes par e-mail, mais lorsque vous êtes élu lors d'un événement ou lorsque vous nous contactez",
      line_title_4: "Nous vous contacterons en utilisant cette ligne @.",
      line_title_5: "Une fois que vous vous êtes inscrit en tant que contributeur, veuillez également vous inscrire à LINE @"
    },
    raking_info: "Aujourd'hui  {{dailyRank}} prix/mois  {{monthlyRank}} place",
    update: "Mise à jour",
    pointCheertext: "J'ai fait {{cheerCount}} kawaii en utilisant {{point}} PT",
    contact: {
      title: "Contact",
      contact_email: "Email du contact",
      description: "Pour toute demande, veuillez contacter l'e-mail suivant."
    },
    reported: "Signalé",
    sent_reset_password: "J'ai envoyé un mot de passe à mon adresse e-mail.",
    kawaii_history: "Liste des publications de Kawaii",
    favorite_player: "Liste des calques préférés",
    empty_favorite_player: "Il n'y a pas de calque préféré",
    empty_kawaii_history: "Il n'y a pas de poste que KAWAII",
    aggregation_period: "Période d'agrégation",
    premium_player_feed: "ALIMENTATION DE COUCHE PREMIUM",
    number_of_kwaii: "Kawaii Number Trend",
    ranking_result: " Classement des résultats",
    ranking_achievements: "Réalisation du classement",
    paid: "Payé KAWAII",
    free: "KAWAII gratuit",
    player_apply: {
      title: "Inscription cosplayeuse",
      name: "Nom (non affiché)",
      name_mandatory: "Le nom complet est requis",
      name_error: "Veuillez entrer votre nom avec 25 caractères ou moins",
      name_kana: "Nom Kana (caché)",
      name_kana_mandatory: "Le nom kana est requis",
      name_kana_error: "Veuillez saisir le nom complet en 25 caractères ou moins",
      gender: "Sexe (non illustré)",
      women: "Une femme",
      men: "Les hommes",
      blood_type: "Groupe sanguin",
      type: "Type {{value}}",
      area: "Domaine d'activité",
      country: "Pays",
      birthday: "Date de naissance",
      birthday_mandatory: "La date de naissance est obligatoire",
      phone: "Numéro de téléphone",
      twitter_username: "nom d'utilisateur twitter",
      twitter_username_required: "un nom d'utilisateur twitter est requis (par exemple @cospo)",
      player_type: "Cosplayeuse/VTuber選択",
      player_type_error: "un nom Cosplayeuse/VTuber",
      cosplayer: "Cosplayeuse",
      vtuber: "VTuber",
      instagram: "Nom de compte Instagram",
      url_name: "Autre URL.",
      cosplay_history: "Histoire du cosplay",
      favorite: "Nom et titre du personnage préféré",
      message: "Message, présentation du profil, etc",
      message_mandatory: "L'introduction du profil est obligatoire",
      message_error: "Veuillez saisir le nom avec 1 000 caractères ou moins",
      when_submitter: "Où j'ai découvert la candidature du demandeur",
      social: "Social media, WEB, etc.",
      cosplayer_archive: "Archive des cosplayeurs",
      invitation: "Invitation du KAWAII JAPON",
      acquaintance: "Présentation de connaissances",
      other: "Autre",
      name_introducer: "Nom de l'introducteur",
      application: "Candidature"
    },
    daily_ranking: {
      title: "Classement quotidien",
      one: "1ère place",
      two: "2ème place",
      three: "3e place",
      place: "Fois"
    },
    monthly_ranking: {
      title: "Classement mensuel",
      one: "1st-3rd place",
      two: "4th-10th place",
      three: "11th-50th place",
      place: "Fois"
    },
    login_meta: {
      meta1: `Si vous êtes déjà enregistré en tant qu'utilisateur avec l'application, vous pouvez vous connecter avec votre adresse e-mail et votre mot de passe. Si vous coopérez avec "Se connecter sur Twitter", vous pourrez vous connecter sur Twitter.`,
      meta2: "Les utilisateurs qui ont enregistré des informations de connexion avec l'application peuvent se connecter avec leur adresse e-mail et mot de passe enregistrés.",
      meta3: "À propos de l'inscription de nouveaux membres",
      meta4: `Nous publions à l'avance le nombre de membres enregistrés dans l'application, donc si vous êtes un nouveau membre, veuillez vous inscrire à partir de l'application "COSPO".`
    },
    favorite: "★ Préférée",
    noFavorite: "☆ Préférée",
    block: "Bloquer",
    unblock: "Débloquer",
    connect_twitter_success: "Connectez le succès de Twitter",
    connect_twitter: "Connectez twitter",
    register_sub: `* De plus, pour l'inscription de nouveaux membres à partir de la version WEB, la coopération du compte <span class="app-color">Twitter est essentielle pour l'authentification</span> du compte et la prévention de la fraude. Si vous n'avez pas de compte Twitter, veuillez vous inscrire après avoir obtenu un compte Twitter à l'avance.`,
    confirm_connect_twitter: "Veuillez vous connecter avec twitter pour terminer l'inscription",
    please_connect_twitter: "Veuillez continuer à établir un lien avec votre compte Twitter.",
    play_guide: "Guide de jeu",
    cosplayer_guide : "Guide de cosplayeur"
  },
};
