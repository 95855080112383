export const locale = {
  lang: 'en',
  data: {
    code: "ENGLISH",
    languages: {
      en: "English",
      ja: "Japanese",
      fr: "French",
      kor: "Korea",
      china: "Simplified Chinese",
      china_tw: "Traditional Chinese"
    },
    language: "English",
    slogan: "Discover the post of your favorite cosplay model and try to KAWAII (cheer)",
    menu: {
      about: "About",
      feed: "Feed",
      event: "Event",
      ranking: "Ranking",
      ranking_past: "Ranking (past)",
      news: "News",
      movie: "Movie",
      search_cosplayer: "Search cosplayer",
      search_post: "Search post",
      my_page: "My page",
      favorite: "Favorite",
      kawaii_history: "Kawaii history",
      kawaii_total: "Kawaii total",
      cosplayer_apply: "Cosplayer apply",
      info_edit: "Info edit",
      block_list: "Block list",
      login_setting: "Login setting",
      withdrawal: "Withdrawal"
    },
    footer: {
      term: "Terms of use",
      contact_us: "Contact us",
      faq: "FAQ",
      company: "Corporate",
      privacy: "Privacy",
      commercial_code: "Display based on Specified Commercial Law Transaction Law",
      company_info: "Operating Company Guide",
      shiketsuhou: "Display based on Fund Settlement Law"
    },
    login_twitter: "Sign In With Twitter",
    email: "Email",
    password: "Password",
    login: "Login",
    email_error: "Please enter the correct email address",
    password_min: "Password must be at least 4 characters",
    forgot_password: "Forgot your password",
    register: "Registration",
    email_required: "Email address is required",
    password_required: "Password is required",
    login_error: "The email address or password is different",
    reset_password: "Reset your password",
    reissue: "Reissue",
    please_login: "Please login",
    register_title: 'Please enter the following contents and press the "register".',
    nickname: "Nickname",
    nickname_min: "Please enter the nickname in 25 characters or less",
    nickname_required: "Nickname is required",
    confirm_password: "Confirm password",
    password_not_match: "The password does not match",
    agree_term: "Agree to the terms of use",
    please_agree_term: "Please accept the terms of use",
    premiun_layer: "Premium layer",
    normal_layer: "Nomal layer",
    next: "Next",
    event: "Event",
    period_of_accept: "Reception period",
    fan_ranking: "Fan Rankings",
    post_login: 'Login to "KAWAII"',
    event_rankings: "Event rankings",
    today: "Today",
    week: "Week",
    month: "Month",
    rankings: "Rankings",
    normal_layer_feed: "Normal layer feed",
    points: "Points",
    more: "More",
    past_ranking: "Past ranking",
    profile: "Profile",
    posts: "List posts",
    number_result: "Number Trends / Results",
    back_list: "Back to list",
    change_user_info: "Change user information",
    avatar: "Avatar",
    message: "Message",
    message_max: "Please enter your nickname in 1000 characters or less",
    cosplayer_list: "Cosplayer list",
    search_result: "Search result",
    register_thank: "Thank you for registering.",
    click_mypage: "Click here for my page",
    kwaii_player: "Player list",
    empty_kwaii_player: "There are no KAWAII players",
    cosplayer_block: "Block cosplayers",
    cosplayer_block_empty: "No players are blocking.",
    change_password: "Change password",
    password_reconfirm: "Password reconfirm",
    change: "Change",
    new_post: "New post",
    event_join: "Event join",
    posting_guide: "Posting guide",
    kawaii_achivements: "Kawaii achivements",
    phone: "Phone",
    orther_url: "Orther URL",
    bank_name: "Bank name",
    branch_name: "Branch name",
    branch_number: "Branch number",
    account_type: "Account Type( Normal or overdraft",
    account_number: "Account number",
    account_name: "Account name",
    image: "Image",
    required: "Required",
    content: "Content",
    please_select: "Please select",
    post_to_twitter: "Post to Twitter",
    post_subtitle: "Posts with 140 characters or less are omitted when linking with Twitter",
    post: "Post",
    post_confirm: "May I post it?",
    delete: "Delete",
    edit: "Edit",
    confirm_delete_post: "May I delete it?",
    post_edit: "Post edit",
    join_event: {
      title: 'Events you can attend',
      subtitle: "Cannot be canceled after participating in the event.",
      start: "Entry start ",
      participate: "Participate",
      participating: "Participating"
    },
    reception_period: "Reception period",
    level: "Level",
    next_lever: "To the next level ",
    my_page: {
      position: "Position",
      great_game: "This is a great game.",
      continue_login: "Continuous login",
      great_app: "It's a great app.",
      we_have_won: "We have won.",
      day_two: "Day two"
    },
    post_detail: {
      title: "Post detail",
      post_reflected: "KAWWAII of this post is reflected in the ranking during the entry",
      congratulations: "Congratulations",
      level_is: "The level is",
      we_help_you: "We are here to help you.",
      stamina: "Stamina",
      from: "From",
      kwaii_not: 'KAWAII " can not!',
      current_point: "Current point",
      bottom_page: "From the bottom of the page",
      with: "With",
      kwaii_possible: '"KAWAII" is possible',
      point_purchase: "Point purchase screen is here",
      number_of_point: "Number of points",
      retention_points: "Retention points",
      input_limit: "Input limit",
      violation: "Reporting violations",
      please_enter_points: "Please enter the points.",
      enter_points: "Enter points in multiples of 10.",
      points_not_enough: "The point is not enough.",
      max_lever_up: "The number of points above the 2 Level Up has been entered. Enter a maximum of 1 level up points."
    },
    purchase: {
      add_point: "Add points",
      bonus_point: "Bonus points will be awarded when you purchase.",
      enought_to_buy: "It has become profitable enough to buy together",
      prossetion: "Possession points",
      buy_with_paypal: "Buy with PayPal",
      view_advertisement: "View Advertisement",
      after_click: "After clicking the button above, on the next screen",
      email_password_required: "COSPO ID (email address)and password",
      please_login: "Please log in.( Only for the first time）",
    },
    logout_confirm: "Can I really log out?",
    unsubscribe_confirm: "Can I really unsubscribe?",
    withdrawal_thank: "It was a great experience.",
    notify: {
      title: 'Notification',
      cancel: "Cancel",
      close: "Close",
      confirm: "Confirm"
    },
    about: {
      how_to_start: "How to start",
      post_title: "To post photos and have fun in COSPO, you need to apply for Contributor",
      kawaii: "KAWAII",
      support_your_player: 'Support your favorite layers with "KAWAII"!',
      recover_in: "KAWAII recovers in 10 minutes 1KAWAII. KAWAII can also be recovered using points.",
      put_together: "You can put KAWAII together using the Points you have.",
      kawaii_to_point: "1KAWAII= 10 points",
      kwaii_lever_up: "In summary, KAWAII can only enter the number before the level up.",
      input_limit: "The input limit display shows the remaining number up to the level up.",
      example_input_limit: 'Example: when the input limit is 100pt',
      lever_up_kwaii: "To level up with KAWAII 10 more times and make KAWAII fully recover",
      can_only_put_together: "You can only put together KAWAII up to 90pt using 9KAWAII.",
      great_game: "This is a great game.",
      first_login_change: "When the date changes and the first login, the login bonus screen is displayed.",
      login_day_total: "Displays the current number of consecutive login days/ total login days/ earned points.",
      lever_up: "LEVEL UP",
      regularly_hosts: "COSPO regularly hosts various events.",
      many_types: "There are many types of events such as magazine publication and event appearances",
      determined_from_user: "Who will be chosen will be determined by KAWAII from the user.",
      dream_layer_san: "Your KAWAII might be a step that leads to the dream of the layer-San!",
      event_page: "When holding a new event, we will inform you in the news and event page.",
      in_addition: "In addition, we will inform you at any time in the e-mail magazine that COSPO distributes.",
      event_and_daily: "The ranking will be based on events and daily weekly monthly reports.",
      ranking_vary_according: "The ranking will vary according to the number of KAWAII.",
      ranking_is_update: "The ranking is updated to 0 minutes per hour, one hourly points are reflected.",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      in_aggregate: "In aggregate",
      weekly_day: "Every Sunday 0:00〜tally in the period of Saturday 23:59",
      monthly_day: "1st day of each month 0:00 〜 end of month 23:59",
      kwaii_article: "KAWAII on the article will be reflected immediately.",
      fan_ranking: "Fan ranking is the ranking of KAWAII users in the post.",
      in_addition_totals: "In addition to daily weekly monthly totals, you can also see the previous day's rankings.",
      in_addition_events: "In addition, you can also see the fan ranking for the ranking such as events.",
      twitter_cooperation: "When you use the Twitter cooperation function, please be careful because it will not be reflected in the full text if it exceeds 140 characters",
      url_post_comment: "If the URL is listed in the post comment, it will be linked automatically.",
      post_title_1: "After posting, the event corresponding to the bottom of the screen posted is displayed when linking is possible.",
      post_title_2: "You can link one event per one post. １ one event per post.",
      post_title_3: "If you're participating in multiple events over the same period, you'll need to post accordingly.",
      post_title_4: "If it is not set at the time of posting, Please note that it is not reflected as an event point even if it is KAWAII.",
      post_title_5: "Since it is not possible to reflect the point later, please check whether it is possible to peg sure.",
      post_ranking: "POST RANKING",
      to_cospo: "To COSPO",
      premium_rank: "There are two types of premium rank and normal rank.",
      participate_rank: "There are restrictions on which events can participate depending on the rank.",
      about_ranking: "About ranking up to premium",
      rank_normal: "You can rank up from the normal rank to the premium.",
      monthly_ranking: 'Monthly ranking if you rank within the position of "５"',
      after_submit_application: "After submitting an application, the application has been reviewed by the administration",
      rank_up_only: "We will rank up only if we have passed the screening, and we will contact you from the management.",
      please_note: "Please note that we cannot answer the screening criteria and results.",
      point_bonus: "POINT BONUS",
      kwaii_sent_viewer: "In COSPO, it is one of the KAWAII sent from the viewer to the person who posted",
      kwaii_sent_points: "According to the number of KAWAII (paid KAWAII) sent using paid points",
      we_will_reward: "We will give you a reward.",
      kwaii_free_points: "* KAWAII in free points will not be converted into rewards.",
      cash_value: "Cash value",
      kwaii_to_yen: "1 paid KAWAII= 1 yen",
      point_bonus_1: "The acquired KAWAII is aggregated at the end of each month in operation, and it will be transferred to the end of the next month.",
      point_bonus_2: "If the account has not been registered, we will contact you from the operation after the transfer.",
      point_bonus_3: "Payment may be delayed.",
      point_bonus_4: "For transfer account, please register the bank name, branch name, Account Number, Account Name of the transfer destination from the profile field of the sidebar.",
      point_bonus_5: "The reward will be paid for more than 10,000 paid KAWAII.",
      point_bonus_6: "If the number of KAWAII is less than 10,000 paid KAWAII,you will be able to carry over the aggregate after the next month,and the payment procedure will be possible in the month when the total number of paid KAWAII is reached 10,000.",
      point_bonus_7: "In the future, we plan to be able to handle the transfer even if it is less than 10,000 paid KAWAII only if you can bear the transfer fee.",
      point_bonus_8: "In addition, we plan to change the transfer so that it is done when the payment application is made in the future.",
      point_bonus_9: "You can check the accumulated number of KAWAII that has been acquired from the sidebar“KAWAII acquisition results",
      line: "LINE@",
      line_title_1: "It's a great way to get the most out of your work and get the most out of it.",
      line_title_2: "We have a dedicated line@contributor available.",
      line_title_3: "We accept inquiries by e-mail, but when you are elected at an event or when you contact us",
      line_title_4: "We will contact you using this line@.",
      line_title_5: "Once you have registered as a contributor, please also register to LINE@"
    },
    raking_info: "Today {{dailyRank}} price/month {{monthlyRank}} place",
    update: "Update",
    pointCheertext: "I did {{cheerCount}}kawaii using {{point}}PT",
    contact: {
      title: "Contact",
      contact_email: "Contact email",
      description: "For inquiries, please contact the following e-mail."
    },
    reported: "Reported",
    sent_reset_password: 'I sent a password to my email address.',
    kawaii_history: "List of posts by Kawaii",
    favorite_player: "List of favorite layers",
    empty_favorite_player: "There is no favorite layer",
    empty_kawaii_history: "There is no post that KAWAII",
    aggregation_period: "Aggregation period",
    premium_player_feed: "PREMIUM LAYER FEED",
    number_of_kwaii: "Number of Kawaii",
    ranking_result: "Ranking results",
    ranking_achievements: "Ranking achievements",
    paid: "Paid",
    free: "Free",
    player_apply: {
      title: "Cosplayer registration",
      name: "Name (not shown)",
      name_mandatory: "Name is mandatory",
      name_error: "Please enter your full name in 25 characters or less",
      name_kana: "Name Kana (not shown)",
      name_kana_mandatory: "Name Kana is mandatory",
      name_kana_error: "Please enter your full name (Kana) in 25 characters or less",
      gender: "Gender (hidden)",
      women: "Women",
      men: "Men",
      blood_type: "Blood type",
      type: "Type {{value}}",
      area: "Areas of activity",
      country: "Country",
      birthday: "Date of birth",
      birthday_mandatory: "Date of birth is mandatory",
      phone: "Phone number (hidden)",
      twitter_username: "Twitter username",
      twitter_username_required: "Twitter username is required (e.g.@ cospo)",
      player_type: "Cosplayer/VTuber",
      player_type_error: "Cosplayer/VTuberは必須です is required",
      cosplayer: "Cosplayer",
      vtuber: "VTuber",
      instagram: "Instagram account name",
      url_name: "Welcome to the URL website.",
      cosplay_history: "Cosplay history",
      favorite: "Favorite character name, title",
      message: "Message, profile introduction, etc.",
      message_mandatory: "Profile introduction is mandatory",
      message_error: "Please enter your name in less than 1000 characters",
      when_submitter: "Where you found out about the submitter application",
      social: "Social media, WEB, etc.",
      cosplayer_archive: "Cosplayers archive",
      invitation: "Invitation from KAWAII JAPAN",
      acquaintance: "Introduction of acquaintance",
      other: "Other",
      name_introducer: "Name of introducer",
      application: "Application"
    },
    daily_ranking: {
      title: "Daily ranking",
      one: "1st place",
      two: "2nd place",
      three: "3rd place",
      place: "times"
    },
    monthly_ranking: {
      title: "Monthly ranking",
      one: "1st-3rd place",
      two: "4th-10th place",
      three: "11th-50th",
      place: "times"
    },
    login_meta: {
      meta1: `If you have already registered as a user with the app, you can log in with your email address and password. If you cooperate with "Sign in on Twitter", you will be able to sign in on Twitter.`,
      meta2: "Users like you have been registered login information in the app can log in at the registered e-mail address and password.",
      meta3: "About new member registration",
      meta4: `Because we have the prior release of the number of member registration person in the app new member registration is sorry to trouble you, thank you to member registration than"COSPO" app.`
    },
    favorite: "★ Favorite",
    noFavorite: "☆ Favorite",
    block: "Block",
    unblock: "Unblock",
    connect_twitter_success: "Connect twitter success",
    connect_twitter: "Connect twitter",
    register_sub: `*In addition, for new member registration from the WEB version,<span class="app-color"> Twitter account cooperation is essential</span> for account authentication and fraud prevention. If you do not have a Twitter account, please register after obtaining a Twitter account in advance.`,
    confirm_connect_twitter: "Please connect with twitter to complete the registration",
    please_connect_twitter: "Please continue to link with your Twitter account.",
    play_guide: "Play guide",
    cosplayer_guide: "Cosplayer guide"
  },

};
