export const locale = {
  lang: 'zh-TW',
  data: {
    code: "CHINESE_TW",
    languages: {
      en: "英语",
      ja: "日本",
      fr: "フランス語",
      kor: "法文",
      china: "简体中文",
      china_tw: "繁体中文"
    },
    language: "China Tw",
    slogan: "让我们发现您最喜欢的角色扮演模型和支持（KAWAII）的帖子",
    menu: {
      about: "新闻",
      feed: "饲料",
      event: "一个事件",
      ranking: "排行",
      ranking_past: "排名（过去）",
      news: "新闻",
      movie: "电影",
      search_cosplayer: "角色扮演者搜寻",
      search_post: "发布搜寻",
      my_page: "我的页面",
      favorite: "喜爱",
      kawaii_history: "卡哇伊历史",
      kawaii_total: "总计",
      cosplayer_apply: "提交者申请",
      info_edit: "编辑用户信息",
      block_list: "黑名单",
      login_setting: "更改密码",
      withdrawal: "退出"
    },
    footer: {
      term: "服务条款",
      contact_us: "查询",
      faq: "经常问的问题",
      company: "企业法人",
      privacy: "个人信息的处理",
      commercial_code: "根据指定的商法进行显示",
      company_info: "经营公司信息",
      shiketsuhou: "根据《资金清算法》进行显示"
    },
    login_twitter: "使用Twitter登录",
    email: "邮件地址",
    password: "密码",
    login: "登录",
    email_error: "请输入正确的电子邮件地址",
    password_min: "请输入至少4个字符的密码",
    forgot_password: "如果您忘记密码，请点击这里",
    register: "点击这里进行会员注册",
    email_required: "电子邮件地址为必填项",
    password_required: "需要密码",
    login_error: "错误的电子邮件地址或密码",
    reset_password: "重设密码",
    reissue: "补发",
    please_login: "请登录",
    register_title: "请在下面输入详细信息，然后按“注册”。",
    nickname: "昵称",
    nickname_min: "请输入25个字符或更少的昵称",
    nickname_required: "昵称为必填项",
    confirm_password: "确认密码",
    password_not_match: "密码不匹配",
    agree_term: "我同意条款和条件",
    please_agree_term: "请接受使用条款",
    premiun_layer: "优秀层",
    normal_layer: "普通层",
    next: "次へ",
    event: "一个事件",
    period_of_accept: "接待期",
    fan_ranking: "粉丝排行",
    post_login: "登录“ KAWAII”",
    event_rankings: "活动排名",
    today: "今天",
    week: "周",
    month: "月亮",
    rankings: "排行",
    normal_layer_feed: "普通层饲料",
    points: "点",
    more: "更多",
    past_ranking: "过去的排名",
    profile: "个人资料",
    posts: "职位清单",
    number_result: "卡哇伊数字趋势",
    back_list: "返回索引",
    change_user_info: "更改用户信息",
    avatar: "图片",
    message: "信息",
    message_max: "请输入一个不超过1000个字符的昵称",
    cosplayer_list: "角色扮演者列表",
    search_result: "发布搜索结果",
    register_thank: "感谢您注册。",
    click_mypage: "点击这里进入我的页面",
    kwaii_player: "KAWAII选手名单",
    empty_kwaii_player: "没有玩家有KAWAII",
    cosplayer_block: "阻止角色扮演",
    cosplayer_block_empty: "没有玩家在阻止",
    change_password: "更改密码",
    password_reconfirm: "确认密码",
    change: "更改",
    new_post: "最新帖子",
    event_join: "活动参与",
    posting_guide: "KAWAII收购记录",
    kawaii_achivements: "海报说明",
    phone: "电话号码",
    orther_url: "其他网址",
    bank_name: "银行名称",
    branch_name: "分店名称",
    branch_number: "分行号码",
    account_type: "帐户类型（普通或当前）",
    account_number: "账号",
    account_name: "帐户名（假名）",
    image: "图片",
    required: "*需要",
    please_select: "请选择",
    content: "内容",
    post_to_twitter: "在Twitter上发布",
    post_subtitle: "*当您与Twitter联系时，您将能够阅读大约140个字符或一个学者。",
    post: "寄存器",
    post_confirm: "您确定要发布吗？",
    delete: "删除",
    edit: "编辑",
    confirm_delete_post: "你确定要删除吗？",
    post_edit: "后期编辑",
    join_event: {
      title: '可用事件',
      subtitle: "*参加活动后无法取消。",
      start: "输入开始",
      participate: "参加",
      participating: "参加"
    },
    reception_period: "接待期",
    level: "水平",
    next_lever: "进入下一个层次",
    my_page: {
      position: "秩",
      great_game: "登录奖金",
      continue_login: "连续登录",
      great_app: "总登录",
      we_have_won: "韩元",
      day_two: "天"
    },
    post_detail: {
      title: "发布详细信息",
      post_reflected: "在此帖子中反映KAUWAII的条目中的排名",
      congratulations: "恭喜！",
      level_is: "级别是",
      we_help_you: "上去了！",
      stamina: "耐力",
      from: "从",
      kwaii_not: '无法完成“ KAWAII”！',
      current_point: "当前点",
      bottom_page: "从页面底部",
      with: "使用",
      kwaii_possible: '有可能“ KAWAII”',
      point_purchase: "单击此处进入积分购买屏幕",
      number_of_point: "点数",
      retention_points: "保持点",
      input_limit: "输入限制",
      violation: "!!报告违规",
      please_enter_points: "请输入积分。",
      enter_points: "请输入10点的倍数。",
      points_not_enough: "积分不足。",
      max_lever_up: "输入了2级以上的点数。请输入最多1级的分数。"
    },
    purchase: {
      add_point: "加分",
      bonus_point: "购买时将获得奖励积分。",
      enought_to_buy: "你买的越多，你越省钱。",
      prossetion: "拥有点",
      buy_with_paypal: "用贝宝购买",
      after_click: "*单击上面的按钮后，在下一个屏幕上",
      email_password_required: "输入您的COSPO ID（电子邮件地址）和密码",
      please_login: "请登录。 （仅限第一次）"
    },
    logout_confirm: "您确定要注销吗？",
    unsubscribe_confirm: "您确定要离开吗？",
    withdrawal_thank: "感谢您的使用。",
    notify: {
      title: '通知',
      cancel: "取消",
      close: "关",
      confirm: "确认书"
    },
    about: {
      how_to_start: "如何开始",
      post_title: "您需要提交申请才能将照片发布到COSPO并享受它们。",
      kawaii: "卡哇伊",
      support_your_player: '用“ KAWAII”支持您喜欢的图层！',
      recover_in: "KAWAII将在10分钟内恢复1台KAWAII。 KAWAII也可以通过使用点来恢复。",
      put_together: "您可以使用自己的积分来集体进行KAWAII。",
      kawaii_to_point: "1KAWAII = 10点转换",
      kwaii_lever_up: "KAWAII只能输入升级前的号码。",
      input_limit: "输入的上限显示将显示直到升级为止的剩余数量。",
      example_input_limit: "示例：当输入上限为100pt时",
      lever_up_kwaii: "KAWAII再增加10个KAWAII，所有KAWAII都恢复了，",
      can_only_put_together: "使用90pt只能将90 KAWAII合并为KAWAII。",
      great_game: "登录奖金",
      first_login_change: "当日期更改并首次登录时，将显示登录奖金屏幕。",
      login_day_total: "显示当前的连续登录天数/登录天数总数/获取点数。",
      lever_up: "升级",
      regularly_hosts: "COSPO定期举行各种活动。",
      many_types: "活动内容类型很多，例如杂志和活动露面",
      determined_from_user: "用户的KAWAII决定选择谁。",
      dream_layer_san: "您的KAWAII可能是实现梦想的第一步！",
      event_page: "我们将在新闻和事件页面上通知您有关新事件的信息。",
      in_addition: "此外，我们会将您发布在COSPO发布的新闻通讯上。",
      event_and_daily: "将显示事件和“每日每周一次”的排名。",
      ranking_vary_according: "排名根据KAWAII的数量而变化。",
      ranking_is_update: "排名会在每小时0分钟每小时更新一次，并反映每小时的积分。",
      daily: "日常",
      weekly: "每周",
      monthly: "每月一次",
      in_aggregate: "计算依据",
      weekly_day: "从每个星期日的0:00到星期六的23:59汇总",
      monthly_day: "从每个月的第一天0:00到该月的最后一天23:59计算",
      kwaii_article: "KAWAII的文章会立即反映出来。",
      fan_ranking: "粉丝排名是发布KAWAII的用户的排名。",
      in_addition_totals: "除了每日，每周，每月和累计总数，您还可以查看前一天的排名。",
      in_addition_events: "您还可以查看事件的粉丝排名和其他排名。",
      twitter_cooperation: "使用Twitter集成功能时，请注意，如果超过140个字符，则不会显示全文。",
      url_post_comment: "如果您在帖子评论中包含该URL，它将自动链接。",
      post_title_1: "发布后，如果链接完成，则相应事件将显示在发布屏幕的底部。",
      post_title_2: "您可以为每个帖子关联一个事件。",
      post_title_3: "如果您在同一时期参加多个活动，则需要相应地发布。",
      post_title_4: "请注意，如果您在发布时未进行设置，即使它是KAWAII，也不会反映为事件点。",
      post_title_5: "您以后无法反映这些要点，因此请确保已链接它们。",
      post_ranking: "贡献者等级",
      to_cospo: "COSPO有",
      premium_rank: "有两种类型，高级等级和普通等级。",
      participate_rank: "根据等级，可以参加的活动有限制。",
      about_ranking: "关于到高级的等级",
      rank_normal: "您可以从普通等级升级到高级。",
      monthly_ranking: "如果您在每月第5名中排名",
      after_submit_application: "由投稿人申请并经过管理方审查后",
      rank_up_only: "关于考试，我们只会在等级通过后才会升级，我们会与您的管理层联系。",
      please_note: "请注意，我们无法回答有关检查标准和结果的问题。",
      point_bonus: "积分奖励",
      kwaii_sent_viewer: "在COSPO中，从浏览用户发送到海报的KAWAII中，",
      kwaii_sent_points: "取决于使用付费点发送的KAWAII（付费KAWAII）的数量",
      we_will_reward: "我会给你奖励的。",
      kwaii_free_points: "*带有免费积分的KAWAII不会转换为奖励。",
      cash_value: "兑换金额",
      kwaii_to_yen: "1付费KAWAII = 1日元",
      point_bonus_1: "所获得的KAWAII将在运营期间的每个月底汇总，并将转移到第二天的月底。",
      point_bonus_2: "转移后，只有支付奖励的人会从操作中得到通知，但是如果未注册帐户，",
      point_bonus_3: "付款可能会延迟。",
      point_bonus_4: "对于转帐帐户，请在侧栏的配置文件字段中注册银行名称，分行名称，帐号和转帐目的地的帐户名称。",
      point_bonus_5: "补偿将支付10,000或更多的支付的KAWAII。",
      point_bonus_6: "如果并购数量少于10,000个支付的KAWAII，则将从下个月结转总计，并且在已完成已支付10,000个KAWAII的购买总数的月份就可以进行付款。",
      point_bonus_7: "将来，我们计划仅在您能够承担转账费用并且您的支付的KAWAII少于10,000时才能够处理转账。",
      point_bonus_8: "将来，我们计划进行更改，以便在您申请付款时付款。",
      point_bonus_9: "您可以在“ KAWAII获取记录”侧栏中查看所获取的KAWAII总数。",
      line: "LINE @仅用于海报",
      line_title_1: "在COSPO中，为了顺利发布公告并回答海报，",
      line_title_2: "我们只为海报提供LINE @。",
      line_title_3: "我们也接受通过电子邮件进行的查询，但是当您在活动中被选中或与我们联系时，",
      line_title_4: "我们将主要使用此LINE @与您联系",
      line_title_5: "注册为海报后，请通过LINE @注册。"
    },
    raking_info: "{{dailyRank}}今天/本月{{monthlyRank}}",
    update: "更新",
    pointCheertext: "我使用{{point}} PT做了{{cheerCount}} kawaii",
    contact: {
      title: "联系信息",
      contact_email: "查询邮件",
      description: "如有查询，请联系以下电子邮件。"
    },
    reported: "已报告",
    sent_reset_password: '我向我的电子邮件地址发送了密码。',
    kawaii_history: "Kawaii的帖子列表",
    favorite_player: "最喜欢的图层列表",
    empty_favorite_player: "没有最喜欢的图层",
    empty_kawaii_history: "没有KAWAII发布的帖子",
    aggregation_period: "聚集期",
    premium_player_feed: "优质层饲料",
    number_of_kwaii: "卡哇伊数字趋势",
    ranking_result: "排名成就",
    ranking_achievements: "排名成就",
    paid: "付费卡哇伊",
    free: "自由",
    player_apply: {
      title: "角色扮演者注册",
      name: "名称（未显示）",
      name_mandatory: "必须填写全名",
      name_error: "请输入您的姓名（不超过25个字符）",
      name_kana: "名字假名（未显示）",
      name_kana_mandatory: "必须输入假名",
      name_kana_error: "请输入您的姓名（不超过25个字符）",
      gender: "性别（未显示）",
      women: "女",
      men: "男",
      blood_type: "お住まいの都道府県",
      type: "{{value}}类型",
      area: "活动领域",
      country: "国家",
      birthday: "生日",
      birthday_mandatory: "生日",
      phone: "电话号码（隐藏）",
      twitter_username: "Twitter用户名",
      twitter_username_required: "必须是Twitter用户名（例如@cospo）",
      player_type: "角色扮演者/虚拟主播",
      player_type_error: "必须角色扮演者/虚拟主播",
      cosplayer: "角色扮演者",
      vtuber: "虚拟主播",
      instagram: "Instagram帐号名称",
      url_name: "其他网址。",
      cosplay_history: "角色扮演的历史",
      favorite: "最喜欢的角色名称，标题",
      message: "消息，个人资料介绍等",
      message_mandatory: "需要简介介绍",
      message_error: "请输入不超过1000个字符的名称",
      when_submitter: "我在哪里了解提交者申请",
      social: "SNS，WEB等",
      cosplayer_archive: "角色扮演者存档",
      invitation: "KAWAII JAPAN的邀请",
      acquaintance: "熟人介绍",
      other: "其他",
      name_introducer: "介绍人姓名",
      application: "选择"
    },
    daily_ranking: {
      title: "每日排名",
      one: "第一名",
      two: "第二名",
      three: "第三名",
      place: "时报"
    },
    monthly_ranking: {
      title: "每月排名 ",
      one: "1位～3位",
      two: "4位～10位",
      three: "11位～50位",
      place: "时报"
    },
    login_meta: {
      meta1: `那些已经使用该应用程序注册为用户的用户可以使用其电子邮件地址和密码登录。如果您与“在Twitter上登录”合作，则可以在Twitter上登录。`,
      meta2: "如果您是新用户，则可以从<br>单击此处进行新注册后，使用电子邮件地址，密码和Twitter登录。",
      meta3: "关于新会员注册",
      meta4: "我们会提前向会员注册者发布该应用程序，因此，如果您是新会员，请从“ COSPO”应用程序进行注册。"
    },
    favorite: "☆最喜欢的",
    noFavorite: "★最爱",
    block: "块",
    unblock: "解除封锁",
    connect_twitter_success: "连接Twitter成功",
    connect_twitter: "与Twitter帐户关联",
    register_sub: `*此外，对于从WEB版本进行的新成员注册，需要<span class =“ app-color”> Twitter帐户合作</ span>，以进行帐户身份验证和防止欺诈。如果您没有Twitter帐户，请在事先获得Twitter帐户后注册。`,
    confirm_connect_twitter: "要完成注册，需要Twitter合作。",
    please_connect_twitter: "请继续与您的Twitter帐户链接。",
    play_guide: "播放指南",
    cosplayer_guide : "扮装者指导"
  },
};
